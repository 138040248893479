import React, { useRef, useEffect, useState } from 'react'
import ModelLayout from '../layouts/ModelLayout'

// components
import Description1 from './../components/Model/Description1'

// img
import a3test from './../images/bgs/twobedroom/quecompound.png'
import { Link } from 'react-router-dom'

const TwoBedroom = () => {
  // const ref = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  // const ref4 = useRef()

  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)
  const [active3, setActive3] = useState(false)
  const [active4, setActive4] = useState(false)

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const entry = entries[0]
  //     if (entry.isIntersecting) {
  //       setActive1(true)
  //     } else {
  //       setActive1(false)
  //     }
  //   })
  //   observer.observe(ref.current)
  // }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setActive2(true)
      } else {
        setActive2(false)
      }
    })
    observer.observe(ref2.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setActive3(true)
      } else {
        setActive3(false)
      }
    })
    observer.observe(ref3.current)
  }, [])

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const entry = entries[0]
  //     if (entry.isIntersecting) {
  //       setActive4(true)
  //     } else {
  //       setActive4(false)
  //     }
  //   })
  //   observer.observe(ref4.current)
  // }, [])

  const a3Spec = {
    topSpeed: '788',
    fuel: '2 Bedroom',
    oneToHund: '2 Bathrooms',
    fourthextra : 'Entrance Foyer',
  }

  const s3Spec = {
    desc1: '451',
    desc2: 'Petrol',
    desc3: '2.9',
    span1: 'km/h',
    span2: '',
    span3: 's',
    h31: 'Top Speed',
    h32: 'Fuel',
    h33: '0-100km/h',
    h61: 'Quickest acceleration—from zero to 60 mph* in as little as 3.1 seconds ',
    h62: ' Improved handling and aerodynamics allow for a top speed of 162 mph ',
    h63: ' Dual Motor All-Wheel Drive instantly controls traction and torque, in all weather conditions',
  }

  const rear = {
    desc1: '380',
    desc2: 'Sensor',
    desc3: '670',
    span1: 'l',
    span2: '',
    span3: 'cm',
    h31: 'Boot capacity',
    h32: 'Open/Close',
    h33: 'Height',
    h61: 'Independent motors digitally control torque to the front and rear bedfinishing ',
    h62: ' Dual motors respond to changing conditions in as little as 10 milliseconds ',
    h63: 'Unparalleled traction and control, in all weather conditions ',
  }

  const interior = {
    desc1: '15',
    desc2: 'WiFi',
    desc3: 'All-glass',
    span1: 'inch',
    span2: '',
    span3: '',
    h31: 'Touch Display',
    h32: 'Software',
    h33: 'Roof',
    h61: 'A touchscreen display designed to improve over time ',
    h62: 'Over-the-air software updates introduce new features, functionality and performance ',
    h63: 'An expansive Glass Roof provides more headroom and UV protection ',
  }

  const showAnimation = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.7,
      },
    },
    exit: {
      opactiy: 0,
    },
  }

  const showAnimation2 = {
    initial: {
      opacity: 0,
      scale: 0.9,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
    exit: {
      opactiy: 0,
    },
  }

  return (
    <ModelLayout>
      <div className='model-page'>
        <div className='model-banner model-a3'>
          <Description1
            name={'2-Bedroom STD'}
            specification={a3Spec}
            btn={'Order Now'}
            link={'/product/twobed'}
          />
        </div>

        {/* <div className='model-intro-text mit1'>
          <div>
            <h5>Homes with Warmth</h5>
            <h2>Ground Floor</h2>

            <div>
              <p>
              <b>Kitchen Area (10’ * 9’ 8”): </b>The ambience of the home that offers satisfaction in a hygienic censored capacious kitchen unit with marble countertops, <br/>and 450*450mm Polished Beige Gray Porcelain Floor Tiles, plugged into an ultra-modern, structurally, engineered frame for your comfort. 
              </p>
            </div>

            <div>
              <p>
              <b>Living Area with Dining (19’ * 9’ 8”): </b>A spacious and effervescent lounge elegantly capped with a Plasterboard celling and a 450*450mm Polished Beige Gray Porcelain Floor Tiles, that offers convenience and comfort, and provides, reception and hospitality for the home and your entertained guests for respect and cultural acceptance. A couch potato or the African buzzing bee, whoever, a parlor, <br/>situated to capture the mountainous vegetative cover scenery, invites your appreciation of nature with a dining space for your meal times.
              </p>
            </div>

            <div>
              <h3>Top Safety Pick+</h3>
              <p>
                Model 3 received the IIHS Top Safety Pick+ award, with top
                ratings in all crashworthiness and front crash prevention
                categories.
              </p>
            </div>
          </div>

          <div>
            <img src={safety} alt={safety} />

            <ul>
              <li>
                <h3>
                  <span>1</span>Rigid Structure
                </h3>
                <p>
                  A material mix of aluminum and steel help to achieve the best
                  structural rigidity, and increased occupant safety.
                </p>
              </li>
              <li>
                <h3>
                  <span>2</span>Impact Protection
                </h3>
                <p>
                  Combined with impact-absorbing rails and center pillar
                  reinforcements, the strength and support of the rigid battery
                  pack provides protection from every side.
                </p>
              </li>
              <li>
                <h3>
                  <span>3</span>Very Low Rollover Risk
                </h3>
                <p>
                  The position and weight of the floor-mounted battery pack
                  provides a very low center of gravity—allowing for a very low
                  rollover risk.
                </p>
              </li>
            </ul>

            <Link to='/product/a3'>
              <button>Order Now</button>
            </Link>
          </div>
        </div> */}

      <div className='black-container-parent'>
        <div className='whiteheader'>
            <h2>Homes with Warmth </h2>
        </div>
        <div className='model-banner interiortwo'></div>
        </div>

        {/* <div ref={ref}>
          
        </div> */}

        <div>
        <div className='model-banner kitchentwo'></div>
        <div className='model-intro-text mit2'>
          <div>
            <h5>(10’ * 9’ 8”)</h5>
            <h2>Kitchen Area:</h2>
          </div>

          <div>
            <p>
            The ambience of the home that offers satisfaction in a hygienic censored capacious kitchen unit with marble countertops, 
            and 450*450mm Polished Beige Gray Porcelain Floor Tiles, plugged into an ultra-modern, structurally, engineered frame for your comfort. 
            </p>
          </div>
        </div>
        </div>

        <div className='model-banner livingroomtwo' ref={ref2}>
          {/* {active2 && (
            <Description2
              showAnimation={showAnimation}
              name={''}
              specification={rear}
            />
          )} */}
        </div>

        <div className='model-intro-text mit2'>
          <div>
            <h5>(19’ * 9’ 8”)</h5>
            <h2>Living Area with Dining:</h2>
          </div>

          <div>
            <p>
            A spacious and effervescent lounge elegantly capped with a Plasterboard celling and a 450*450mm Polished Beige Gray Porcelain Floor Tiles, 
            that offers convenience and comfort, and provides, reception and hospitality for the home and your entertained guests 
            for respect and cultural acceptance. A couch potato or the African buzzing bee, whoever, a parlor, situated to capture the mountainous 
            vegetative cover scenery, invites your appreciation of nature with a dining space for your meal times.
            </p>
          </div>
        </div>

        {/* <div className='model-intro-text mit2'>
          <div>
            <h5>Autopilot</h5>
            <h2>Future of Driving</h2>
          </div>

          <div>
            <p style={{ marginBottom: '6rem' }}>
              Autopilot enables your car to steer, accelerate and brake
              automatically within its lane. Full Self-Driving Capability
              introduces additional features and improves existing functionality
              to make your car more capable over time including:
            </p>

            <ul>
              <li>
                <h3>Features</h3>
                <p>
                  A material mix of aluminum and steel help to achieve the best
                  structural rigidity, and increased occupant safety.
                </p>
              </li>
              <li>
                <h3>Full Self-Driving Hardware</h3>
                <p>
                  Every new Model 3 comes standard with advanced hardware
                  capable of providing Autopilot features today, and full
                  self-driving capabilities in the future—through software
                  updates designed to improve functionality over time.
                </p>
              </li>
              <li>
                <h3>The Future of Autopilot</h3>
                <p>
                  All Tesla vehicles have the hardware needed in the future for
                  full self-driving in almost all circumstances, at a safety
                  level we believe will be at least twice as good as the average
                  human driver.
                </p>
              </li>
            </ul>
          </div>
        </div> */}

        <div className='model-banner twobedroomensuite1' ref={ref3}>
          {/* {active3 && (
            <Description2
              showAnimation={showAnimation}
              name={''}
              specification={interior}
            />
          )} */}
        </div>

        <div className='model-intro-text mit2'>
          <div>
            <h5>(11’ * 15’ 0”)</h5>
            <h2>Bedroom 1-Ensuite:</h2>
          </div>

          <div>
            <p>
            Luxury, enrapturing, clean and fairly spacious Master Suite with 450*450mm Polished Beige Gray Porcelain Floor Tiles, designed with a large shower and loo 
            (5’ 0” * 7’ 0”) in the ensuite, decorated with 300*300 Polished Light Gray Porcelain Floor Tiles, non-slip. Provides views over landscaped gardens 
            and mountainous scenery of nature through sliding and projected windows. Elegantly, embedded with wardrobe fittings.
            </p>
          </div>
        </div>

        <div className='model-banner twobedroomensuite2' ref={ref3}>
          {/* {active3 && (
            <Description2
              showAnimation={showAnimation}
              name={''}
              specification={interior}
            />
          )} */}
        </div>

        <div className='model-intro-text mit2'>
          <div>
            <h5>(11’ * 13’ 0”)</h5>
            <h2>Bedroom 2-Ensuite:</h2>
          </div>

          <div>
            <p>
            Exquisite, resplendent, clean and fairly spacious Master Suite with 450*450mm Polished Beige Gray Porcelain Floor Tiles, 
            designed with a large shower and loo (5’ 0” * 7’ 0”) in the ensuite, decorated with 300*300 Polished Light Gray Porcelain Floor Tiles, non-slip.
             Provides views over landscaped gardens and mountainous scenery of nature through sliding and projected windows. Elegantly, embedded with wardrobe fittings. 
            </p>
          </div>
        </div>


        <div className='model-banner twovisitorswashroom' ref={ref3}>
          {/* {active3 && (
            <Description2
              showAnimation={showAnimation}
              name={''}
              specification={interior}
            />
          )} */}
        </div>

        <div className=' model-intro-text mit2'>
          <div>
            {/* <h5>(11’ * 13’ 0”)</h5> */}
            <h2>Visitors’ Washroom</h2>
          </div>

          <div>
            <p>
            Visitors’ Washroom: Situated conveniently to serve both guests and occupants of your second bedroom with 450*450mm Polished Beige Gray Porcelain 
            Floor Tiles, designed with a large shower and loo (5’ 0” * 7’ 0”) in the ensuite, decorated with 300*300 Polished Light Gray Porcelain Floor Tiles, 
            non-slip. 
            </p>
          </div>
        </div>


        <div className='model-banner interior2-a3' />
        <div className='black-container-parent'>
          <div className='model-intro-text black mit3'>
            <div>
              <h5>e-secured Community</h5>
              <h2>Iron-gated:</h2>
            </div>
            <div>
              <p>
              Available with electronic gates, posts at the sides of driveway  entrances and sliding gates, moved by static motors with back-up batteries, 
              remote controls and other settings that can be solar-powered.
              </p>
            </div>
          </div>
        </div>
        <div className='black-container-parent'>
          <div className='black-container'>
            <div className='one'>
              {' '}
              <div className='model-banner interior3-a3' />
              <div className='model-intro-text black'>
                <h2>A 2-Car Lot</h2>
                <div>
                  <p>
                  A consummate Car Lot, designed with a charming pre-cast Floor Slabs of C25N/mm2 interlaced with a quintessential carpet 
                  grass to contain two private vehicles at the iconic foyer.
                  </p>
                </div>
              </div>
            </div>
            <div className='two'>
              {' '}
              <div className='model-banner interior4-a3' />
              <div className='model-intro-text black'>
                <h2>Compound</h2>
                <div>
                  <p>
                  Idyllic inner compound for the units with charming pre-cast Floor Slabs of C25N/mm2 
                  interlaced with a quintessential carpet grass, and floral injections at the façade.
                  </p>
                </div>
              </div>
            </div>
            <div className='three'>
              <div className='model-banner interior5-a3' />
              <div className='model-intro-text black'>
                <h2>Entrance Foyer (5’ * 9’ 8”)</h2>
                <div>
                  <p>
                  Illuminating Entrance Foyer projected with a lush pre-cast Floor Slabs of C25N/mm2 interlaced with a 
                  quintessential carpet grass for your comfort, that offers a brief welcome lobby to usher your guests into the living room area.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='model-intro-text black'>
          <div className='specs-parent' >
            {/* <img src={a3config} alt='/' /> */}
            {/* <div className='specs-flex'>
              {active4 && (
                <>
                  <motion.div
                    variants={showAnimation2}
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    className='specs-cont'
                  >
                    <div className='specs-heads'>
                      <h2>
                        Audi 2-Bedroom <span>Specs</span>
                      </h2>
                    </div>

                    <div className='specs-containers'>
                      <div>
                        <h5>Battery</h5>
                        <h6>Range</h6>
                      </div>

                      <div>
                        <h5>Acceleration</h5>
                        <h6>3.1s 0-60 mph</h6>
                      </div>

                      <div>
                        <h5>Range</h5>
                        <h6>315 miles (EPA est.)</h6>
                      </div>

                      <div>
                        <h5>Drive</h5>
                        <h6>Dual Motor All-Wheel Drive</h6>
                      </div>

                      <div>
                        <h5>Seating</h5>
                        <h6>5 Adults</h6>
                      </div>

                      <div>
                        <h5>Weight</h5>
                        <h6>4,048 lbs</h6>
                      </div>
                    </div>
                  </motion.div>

                  <motion.div
                    variants={showAnimation2}
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    className='specs-cont'
                  >
                    <div className='specs-heads'>
                      <h2>
                        Audi S3 <span>Specs</span>
                      </h2>
                    </div>

                    <div className='specs-containers'>
                      <div>
                        <h5>Battery</h5>
                        <h6>Range</h6>
                      </div>

                      <div>
                        <h5>Acceleration</h5>
                        <h6>3.1s 0-60 mph</h6>
                      </div>

                      <div>
                        <h5>Range</h5>
                        <h6>315 miles (EPA est.)</h6>
                      </div>

                      <div>
                        <h5>Drive</h5>
                        <h6>Dual Motor All-Wheel Drive</h6>
                      </div>

                      <div>
                        <h5>Seating</h5>
                        <h6>5 Adults</h6>
                      </div>

                      <div>
                        <h5>Weight</h5>
                        <h6>4,048 lbs</h6>
                      </div>
                    </div>
                  </motion.div>
                </>
              )}
            </div> */}
          </div>

          <h1>Cul-de-sac</h1>

          <div className='specs-parent'>
            <Link to='/product/twobed'>
              <button>Order Now</button>
            </Link>

            <img src={a3test} alt='' />
          </div>
        </div>
      </div>
    </ModelLayout>
  )
}

export default TwoBedroom
