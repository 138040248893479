import React, { useRef, useEffect, useState } from 'react'
import HomeLayout from '../layouts/HomeLayout'
import { Link } from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'

const Homepage = () => {
  const ref1 = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  // const ref4 = useRef()
  // const ref5 = useRef()
  // const ref6 = useRef()

  const refR = useRef()
  const refS = useRef()

  const [m1, setM1] = useState(true)
  const [m2, setM2] = useState(false)
  const [m3, setM3] = useState(false)
  // const [m4, setM4] = useState(false)
  // const [m5, setM5] = useState(false)
  // const [m6, setM6] = useState(false)

  const [show, setShow] = useState(true)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setM1(true)
      } else {
        setM1(false)
      }
    })

    observer.observe(ref1.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setM2(true)
      } else {
        setM2(false)
      }
    })

    observer.observe(ref2.current)
  }, [])
  

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setM3(true)
      } else {
        setM3(false)
      }
    })

    observer.observe(ref3.current)
  }, [])

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const entry = entries[0]
  //     if (entry.isIntersecting) {
  //       setM4(true)
  //     } else {
  //       setM4(false)
  //     }
  //   })

  //   observer.observe(ref4.current)
  // }, [])

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const entry = entries[0]
  //     if (entry.isIntersecting) {
  //       setM5(true)
  //     } else {
  //       setM5(false)
  //     }
  //   })

  //   observer.observe(ref5.current)
  // }, [])

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const entry = entries[0]
  //     if (entry.isIntersecting) {
  //       setM6(true)
  //     } else {
  //       setM6(false)
  //     }
  //   })

  //   observer.observe(ref6.current)
  // }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setShow(false)
      }
    })

    observer.observe(refR.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setShow(true)
      }
    })

    observer.observe(refS.current)
  }, [])

  return (
    <HomeLayout>
      <div className='p-c'>
        <div className='p-cc fourbedstd'>
          <div className='p-c-child' ref={ref1} />
        </div>

        <div className='p-cc threebedstd'>
          <div>
            <div className='p-c-child' ref={refS} />
          </div>
          
          <div>
            <div className='p-c-child' ref={ref2} />
          </div>
        </div>


        <div className='p-cc twobed'>
          <div className='p-c-child' ref={refR} />
          <div className='p-c-child' ref={ref3} />
        </div>

        {/* <div className='p-cc threebedext'>
          <div className='p-c-child' ref={refR} />
          <div className='p-c-child' ref={ref4} />
        </div> */}

        {/* <div className='p-cc threebedstd'>
          <div className='p-c-child' ref={refR} />
          <div className='p-c-child' ref={ref5} />
        </div> */}

        {/* <div className='p-cc twobed'>
          <div className='p-c-child' ref={refR} />
          <div className='p-c-child' ref={ref6} />
        </div> */}


        {/* <AnimatePresence>
          {m1 && (
            <Headings
              title={'4 Bedroom Prem'}
              description={'Order Online for Order Now for 16 Week Delivery'}
              btn1={'Custom Home'}
              btn2={'Existing Home'}
              link={'/product/fourbedprem'}
              link2={'/fourbedprem'}
            />
          )}
        </AnimatePresence> */}

        {/* {show && (
          <AnimatePresence>
            {m2 && (
              <Headings
                title={'4 Bedroom Ext'}
                description={'Order Online for Order Now for 16 Week Delivery'}
                btn1={'Custom Home'}
                btn2={'Existing Home'}
                link={'/product/fourbedext'}
                link2={'/fourbedext'}
              />
            )}
          </AnimatePresence>
        )} */}

        <AnimatePresence>
          {m1 && (
            <Headings
              title={'4 Bedroom Std'}
              description={'Order Online for Order Now for 16 Week Delivery'}
              btn1={'Custom Home'}
              btn2={'Existing Home'}
              link={'/product/fourbedstd'}
              link2={'/fourbedstd'}
            />
          )}
        </AnimatePresence>

        {/* <AnimatePresence>
          {m4 && (
            <Headings
              title={'3 Bedroom Ext'}
              description={'Order Online for Order Now for 16 Week Delivery'}
              btn1={'Custom Home'}
              btn2={'Existing Home'}
              link={'/product/threebedext'}
              link2={'/threebedext'}
            />
          )}
        </AnimatePresence> */}

        <AnimatePresence>
          {m2 && (
            <Headings
              title={'3 Bedroom Std'}
              description={'Order Online for Order Now for 16 Week Delivery'}
              btn1={'Custom Home'}
              btn2={'Existing Home'}
              link={'/product/threebedstd'}
              link2={'/threebedstd'}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {m3 && (
            <Headings
              title={'2 Bedroom'}
              description={'Order Online for Order Now for 16 Week Delivery'}
              btn1={'Custom Home'}
              btn2={'Existing Home'}
              link={'/product/twobed'}
              link2={'/2-Bedroom'}
            />
          )}
        </AnimatePresence>

      </div>
    </HomeLayout>
  )
}

export default Homepage

const Headings = ({ title, description, btn1, btn2, link, link2 }) => {
  return (
    <motion.div className='overlay-r'>
      <motion.div
        className='overlay-m'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        // transition={{ duration: 0.2 }}
      >
        <div className='tlt-desc'>
          <h1>{title}</h1>
          <h5>{description}</h5>
        </div>
        <div className='overlayy-btns'>
          <Link to={link}>
            <button className='primary-btn'>{btn1}</button>
          </Link>
          <Link to={link2}>
            <button className='secondary-btn'>{btn2}</button>
          </Link>
        </div>
      </motion.div>
    </motion.div>
  )
}
