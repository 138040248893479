import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  models: {
    twobed: {
      basicModel: {
        name: '2-Bedroom',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      hybridModel: {
        name: '2-Bedroom',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      sportModel: {
        name: '',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      performanceFeatureDetails: {},
      paints: [
        [
          ['twobedwhitefront.jpg'],
          ['twobedwhitegloss.jpg'],
          'white.jpg',
          'Included',
          'White',
          0,
        ],
        [
          ['twobedcashmerefront.jpg'],
          ['twobedcashmeregloss.jpg'],
          'cashmere.jpg',
          '$1250',
          'Cashmere',
          1,
        ],
        [
          ['twobedcreamfront.jpg'],
          ['twobedcreamgloss.jpg'],
          'cream.jpg',
          '$1220',
          'Cream',
          2,
        ],
        [
          ['twobeddarkgrayfront.jpg'],
          ['twobeddarkgraygloss.jpg'],
          'darkgrey.jpg',
          '$1550',
          'Dark Gray',
          3,
        ],
        [
          ['twobeddarkwoodfront.jpg'],
          ['twobeddarkwoodgloss.jpg'],
          'darkwood.jpg',
          '$1231',
          'Dark Wood',
          4,
        ],
        [
          ['twobedlightgrayfront.jpg'],
          ['twobedlightgraygloss.jpg'],
          'lightgray.jpg',
          '$3250',
          'Light Gray',
          5,
        ],
        [
          ['twobedlightwoodfront.jpg'],
          ['twobedlightwoodgloss.jpg'],
          'lightwood.jpg',
          '$9250',
          'Light Wood',
          6,
        ],
        [
          ['twobedmiddlewoodfront.jpg'],
          ['twobedmiddlewoodgloss.jpg'],
          'modwood.jpg',
          '$1750',
          'Middle Wood',
          7,
        ],
        [
          ['twobedbluefront.jpg'],
          ['twobedbluegloss.jpg'],
          'blue.jpg',
          '$7750',
          'Blue',
          8,
        ],
      ],
      bedfinishing: [
        ['felnanew1.jpg', 'Included', 'Matt', 0],
        ['felnanew2.jpg', '$1200', 'Gloss', 1200],
      ],
      interiors: [
        ['a3interior2.png', 'interior2.jpeg', 'Included', 'Black Interior', 0],
        ['a3interior1.png', 'interior1.jpeg', '$899', 'Red Interior', 899],
      ],
      doors: [
        ['5', 'Included', 'Hatchbag', 0],
        ['5', '$600', 'Limousine', 600],
        ['3', '$1550', 'Coupe', 1550],
      ],
      seats: [['5', 'Included', 'Five Seat Interior', 0]],
      interiorFeautureDetails: {},
      autopilot: {},
    },

    threebedstd: {
      basicModel: {
        name: '3-Bedroom STD',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      hybridModel: {
        name: '3-Bedroom STD',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      sportModel: {
        name: '',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      performanceFeatureDetails: {},
      paints: [
        [
          ['twobedwhitefront.jpg'],
          ['twobedwhitegloss.jpg'],
          'white.jpg',
          'Included',
          'White',
          0,
        ],
        [
          ['twobedcashmerefront.jpg'],
          ['twobedcashmeregloss.jpg'],
          'cashmere.jpg',
          '$1250',
          'Cashmere',
          1,
        ],
        [
          ['twobedcreamfront.jpg'],
          ['twobedcreamgloss.jpg'],
          'cream.jpg',
          '$1220',
          'Cream',
          2,
        ],
        [
          ['twobeddarkgrayfront.jpg'],
          ['twobeddarkgraygloss.jpg'],
          'darkgrey.jpg',
          '$1550',
          'Dark Gray',
          3,
        ],
        [
          ['twobeddarkwoodfront.jpg'],
          ['twobeddarkwoodgloss.jpg'],
          'darkwood.jpg',
          '$1231',
          'Dark Wood',
          4,
        ],
        [
          ['twobedlightgrayfront.jpg'],
          ['twobedlightgraygloss.jpg'],
          'lightgray.jpg',
          '$3250',
          'Light Gray',
          5,
        ],
        [
          ['twobedlightwoodfront.jpg'],
          ['twobedlightwoodgloss.jpg'],
          'lightwood.jpg',
          '$9250',
          'Light Wood',
          6,
        ],
        [
          ['twobedmiddlewoodfront.jpg'],
          ['twobedmiddlewoodgloss.jpg'],
          'modwood.jpg',
          '$1750',
          'Middle Wood',
          7,
        ],
        [
          ['twobedbluefront.jpg'],
          ['twobedbluegloss.jpg'],
          'blue.jpg',
          '$7750',
          'Blue',
          8,
        ],
      ],
      bedfinishing: [
        ['felnanew1.jpg', 'Included', 'Matt', 0],
        ['felnanew2.jpg', '$1200', 'Gloss', 1200],
      ],
      interiors: [
        ['a3interior2.png', 'interior2.jpeg', 'Included', 'Black Interior', 0],
        ['a3interior1.png', 'interior1.jpeg', '$899', 'Red Interior', 899],
      ],
      doors: [
        ['5', 'Included', 'Hatchbag', 0],
        ['5', '$600', 'Limousine', 600],
        ['3', '$1550', 'Coupe', 1550],
      ],
      seats: [['5', 'Included', 'Five Seat Interior', 0]],
      interiorFeautureDetails: {},
      autopilot: {},
    },


    threebedext: {
      basicModel: {
        name: '3-Bedroom Ext',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      hybridModel: {
        name: '3-Bedroom Ext',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      sportModel: {
        name: '',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      performanceFeatureDetails: {},
      paints: [
        [
          ['twobedwhitefront.jpg'],
          ['twobedwhitegloss.jpg'],
          'white.jpg',
          'Included',
          'White',
          0,
        ],
        [
          ['twobedcashmerefront.jpg'],
          ['twobedcashmeregloss.jpg'],
          'cashmere.jpg',
          '$1250',
          'Cashmere',
          1,
        ],
        [
          ['twobedcreamfront.jpg'],
          ['twobedcreamgloss.jpg'],
          'cream.jpg',
          '$1220',
          'Cream',
          2,
        ],
        [
          ['twobeddarkgrayfront.jpg'],
          ['twobeddarkgraygloss.jpg'],
          'darkgrey.jpg',
          '$1550',
          'Dark Gray',
          3,
        ],
        [
          ['twobeddarkwoodfront.jpg'],
          ['twobeddarkwoodgloss.jpg'],
          'darkwood.jpg',
          '$1231',
          'Dark Wood',
          4,
        ],
        [
          ['twobedlightgrayfront.jpg'],
          ['twobedlightgraygloss.jpg'],
          'lightgray.jpg',
          '$3250',
          'Light Gray',
          5,
        ],
        [
          ['twobedlightwoodfront.jpg'],
          ['twobedlightwoodgloss.jpg'],
          'lightwood.jpg',
          '$9250',
          'Light Wood',
          6,
        ],
        [
          ['twobedmiddlewoodfront.jpg'],
          ['twobedmiddlewoodgloss.jpg'],
          'modwood.jpg',
          '$1750',
          'Middle Wood',
          7,
        ],
        [
          ['twobedbluefront.jpg'],
          ['twobedbluegloss.jpg'],
          'blue.jpg',
          '$7750',
          'Blue',
          8,
        ],
      ],
      bedfinishing: [
        ['felnanew1.jpg', 'Included', 'Matt', 0],
        ['felnanew2.jpg', '$1200', 'Gloss', 1200],
      ],
      interiors: [
        ['a3interior2.png', 'interior2.jpeg', 'Included', 'Black Interior', 0],
        ['a3interior1.png', 'interior1.jpeg', '$899', 'Red Interior', 899],
      ],
      doors: [
        ['5', 'Included', 'Hatchbag', 0],
        ['5', '$600', 'Limousine', 600],
        ['3', '$1550', 'Coupe', 1550],
      ],
      seats: [['5', 'Included', 'Five Seat Interior', 0]],
      interiorFeautureDetails: {},
      autopilot: {},
    },


    fourbedstd: {
      basicModel: {
        name: '4-Bedroom STD',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      hybridModel: {
        name: '4-Bedroom STD',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      sportModel: {
        name: '',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      performanceFeatureDetails: {},
      paints: [
        [
          ['twobedwhitefront.jpg'],
          ['twobedwhitegloss.jpg'],
          'white.jpg',
          'Included',
          'White',
          0,
        ],
        [
          ['twobedcashmerefront.jpg'],
          ['twobedcashmeregloss.jpg'],
          'cashmere.jpg',
          '$1250',
          'Cashmere',
          1,
        ],
        [
          ['twobedcreamfront.jpg'],
          ['twobedcreamgloss.jpg'],
          'cream.jpg',
          '$1220',
          'Cream',
          2,
        ],
        [
          ['twobeddarkgrayfront.jpg'],
          ['twobeddarkgraygloss.jpg'],
          'darkgrey.jpg',
          '$1550',
          'Dark Gray',
          3,
        ],
        [
          ['twobeddarkwoodfront.jpg'],
          ['twobeddarkwoodgloss.jpg'],
          'darkwood.jpg',
          '$1231',
          'Dark Wood',
          4,
        ],
        [
          ['twobedlightgrayfront.jpg'],
          ['twobedlightgraygloss.jpg'],
          'lightgray.jpg',
          '$3250',
          'Light Gray',
          5,
        ],
        [
          ['twobedlightwoodfront.jpg'],
          ['twobedlightwoodgloss.jpg'],
          'lightwood.jpg',
          '$9250',
          'Light Wood',
          6,
        ],
        [
          ['twobedmiddlewoodfront.jpg'],
          ['twobedmiddlewoodgloss.jpg'],
          'modwood.jpg',
          '$1750',
          'Middle Wood',
          7,
        ],
        [
          ['twobedbluefront.jpg'],
          ['twobedbluegloss.jpg'],
          'blue.jpg',
          '$7750',
          'Blue',
          8,
        ],
      ],
      bedfinishing: [
        ['felnanew1.jpg', 'Included', 'Matt', 0],
        ['felnanew2.jpg', '$1200', 'Gloss', 1200],
      ],
      interiors: [
        ['a3interior2.png', 'interior2.jpeg', 'Included', 'Black Interior', 0],
        ['a3interior1.png', 'interior1.jpeg', '$899', 'Red Interior', 899],
      ],
      doors: [
        ['5', 'Included', 'Hatchbag', 0],
        ['5', '$600', 'Limousine', 600],
        ['3', '$1550', 'Coupe', 1550],
      ],
      seats: [['5', 'Included', 'Five Seat Interior', 0]],
      interiorFeautureDetails: {},
      autopilot: {},
    },


    fourbedext: {
      basicModel: {
        name: '4-Bedroom Ext',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      hybridModel: {
        name: '4-Bedroom Ext',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      sportModel: {
        name: '',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      performanceFeatureDetails: {},
      paints: [
        [
          ['twobedwhitefront.jpg'],
          ['twobedwhitegloss.jpg'],
          'white.jpg',
          'Included',
          'White',
          0,
        ],
        [
          ['twobedcashmerefront.jpg'],
          ['twobedcashmeregloss.jpg'],
          'cashmere.jpg',
          '$1250',
          'Cashmere',
          1,
        ],
        [
          ['twobedcreamfront.jpg'],
          ['twobedcreamgloss.jpg'],
          'cream.jpg',
          '$1220',
          'Cream',
          2,
        ],
        [
          ['twobeddarkgrayfront.jpg'],
          ['twobeddarkgraygloss.jpg'],
          'darkgrey.jpg',
          '$1550',
          'Dark Gray',
          3,
        ],
        [
          ['twobeddarkwoodfront.jpg'],
          ['twobeddarkwoodgloss.jpg'],
          'darkwood.jpg',
          '$1231',
          'Dark Wood',
          4,
        ],
        [
          ['twobedlightgrayfront.jpg'],
          ['twobedlightgraygloss.jpg'],
          'lightgray.jpg',
          '$3250',
          'Light Gray',
          5,
        ],
        [
          ['twobedlightwoodfront.jpg'],
          ['twobedlightwoodgloss.jpg'],
          'lightwood.jpg',
          '$9250',
          'Light Wood',
          6,
        ],
        [
          ['twobedmiddlewoodfront.jpg'],
          ['twobedmiddlewoodgloss.jpg'],
          'modwood.jpg',
          '$1750',
          'Middle Wood',
          7,
        ],
        [
          ['twobedbluefront.jpg'],
          ['twobedbluegloss.jpg'],
          'blue.jpg',
          '$7750',
          'Blue',
          8,
        ],
      ],
      bedfinishing: [
        ['felnanew1.jpg', 'Included', 'Matt', 0],
        ['felnanew2.jpg', '$1200', 'Gloss', 1200],
      ],
      interiors: [
        ['a3interior2.png', 'interior2.jpeg', 'Included', 'Black Interior', 0],
        ['a3interior1.png', 'interior1.jpeg', '$899', 'Red Interior', 899],
      ],
      doors: [
        ['5', 'Included', 'Hatchbag', 0],
        ['5', '$600', 'Limousine', 600],
        ['3', '$1550', 'Coupe', 1550],
      ],
      seats: [['5', 'Included', 'Five Seat Interior', 0]],
      interiorFeautureDetails: {},
      autopilot: {},
    },

    fourbedprem: {
      basicModel: {
        name: '4-Bedroom Prem',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      hybridModel: {
        name: '4-Bedroom Prem',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500', '$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      sportModel: {
        name: '',
        estDelivery: '',
        price: [500, 500],
        priceDesc: ['$500', '$500'],
        specifications: {
          topSpeed: '',
          fuel: '',
          oneToHund: '',
          towType: '',
        },
      },
      performanceFeatureDetails: {},
      paints: [
        [
          ['twobedwhitefront.jpg'],
          ['twobedwhitegloss.jpg'],
          'white.jpg',
          'Included',
          'White',
          0,
        ],
        [
          ['twobedcashmerefront.jpg'],
          ['twobedcashmeregloss.jpg'],
          'cashmere.jpg',
          '$1250',
          'Cashmere',
          1,
        ],
        [
          ['twobedcreamfront.jpg'],
          ['twobedcreamgloss.jpg'],
          'cream.jpg',
          '$1220',
          'Cream',
          2,
        ],
        [
          ['twobeddarkgrayfront.jpg'],
          ['twobeddarkgraygloss.jpg'],
          'darkgrey.jpg',
          '$1550',
          'Dark Gray',
          3,
        ],
        [
          ['twobeddarkwoodfront.jpg'],
          ['twobeddarkwoodgloss.jpg'],
          'darkwood.jpg',
          '$1231',
          'Dark Wood',
          4,
        ],
        [
          ['twobedlightgrayfront.jpg'],
          ['twobedlightgraygloss.jpg'],
          'lightgray.jpg',
          '$3250',
          'Light Gray',
          5,
        ],
        [
          ['twobedlightwoodfront.jpg'],
          ['twobedlightwoodgloss.jpg'],
          'lightwood.jpg',
          '$9250',
          'Light Wood',
          6,
        ],
        [
          ['twobedmiddlewoodfront.jpg'],
          ['twobedmiddlewoodgloss.jpg'],
          'modwood.jpg',
          '$1750',
          'Middle Wood',
          7,
        ],
        [
          ['twobedbluefront.jpg'],
          ['twobedbluegloss.jpg'],
          'blue.jpg',
          '$7750',
          'Blue',
          8,
        ],
      ],
      bedfinishing: [
        ['felnanew1.jpg', 'Included', 'Matt', 0],
        ['felnanew2.jpg', '$1200', 'Gloss', 1200],
      ],
      interiors: [
        ['a3interior2.png', 'interior2.jpeg', 'Included', 'Black Interior', 0],
        ['a3interior1.png', 'interior1.jpeg', '$899', 'Red Interior', 899],
      ],
      doors: [
        ['5', 'Included', 'Hatchbag', 0],
        ['5', '$600', 'Limousine', 600],
        ['3', '$1550', 'Coupe', 1550],
      ],
      seats: [['5', 'Included', 'Five Seat Interior', 0]],
      interiorFeautureDetails: {},
      autopilot: {},
    },
    
    // q8: {
    //   basicModel: {
    //     name: 'Audi Q8',
    //     estDelivery: 'May 2023',
    //     price: [53000, 50200],
    //     priceDesc: ['$53.000', '$50.200'],
    //     specifications: {
    //       topSpeed: '220',
    //       fuel: 'Diesel',
    //       oneToHund: '5.2',
    //       towType: 'Front Wheel Drive',
    //     },
    //   },
    //   hybridModel: {
    //     name: 'Audi Q8 S Line',
    //     estDelivery: 'June 2023',
    //     price: [63300, 60200],
    //     priceDesc: ['$63.300', '$60.200'],
    //     specifications: {
    //       topSpeed: '270',
    //       fuel: 'Hybrid',
    //       oneToHund: '4.3',
    //       towType: 'Dual Motor All Wheel Drive',
    //     },
    //   },
    //   sportModel: {
    //     name: 'Audi SQ8',
    //     estDelivery: 'November 2023',
    //     price: [155500, 149350],
    //     priceDesc: ['$155.500', '$149.350'],
    //     specifications: {
    //       topSpeed: '451',
    //       fuel: 'Petrol',
    //       oneToHund: '2.9',
    //       towType: 'All Wheel Drive',
    //     },
    //   },
    //   performanceFeatureDetails: {},
    //   paints: [
    //     [
    //       ['q5front.webp', 'q5side.webp', 'q5felna1.webp'],
    //       ['q5front2.webp', 'q5side2.webp', 'q5felna1.webp'],
    //       'white.jpeg',
    //       'Included',
    //       'Ibis White',
    //       0,
    //     ],
    //     [
    //       ['q5frontblack.webp', 'q5sideblack.webp', 'q5felna1black.webp'],
    //       ['q5frontblack2.webp', 'q5sideblack2.webp', 'q5felna2black.webp'],
    //       'black.jpeg',
    //       '$1250',
    //       'Mythos Black',
    //       1250,
    //     ],
    //   ],
    //   bedfinishing: [
    //     ['felnaq51.jpeg', 'Included', '20" Sport Wheels', 0],
    //     ['felnaq52.jpeg', '$2000', '20" Alu Wheels', 1200],
    //   ],
    //   interiors: [
    //     ['q5interior2.webp', 'q5i2.jpeg', 'Included', 'Red Interior', 0],
    //     ['q5interior1.webp', 'q5i1.jpeg', '$899', 'Black Interior', 899],
    //   ],
    //   doors: [['5', 'Included', 'Sportsback', 0]],
    //   seats: [
    //     ['5', 'Included', 'Five Seat Interior', 0],
    //     ['7', '$2800', 'Seven Seat Interior', 2800],
    //   ],
    //   interiorFeautureDetails: {},
    //   autopilot: {},
    // },
  },

  customModel: localStorage.getItem('yourCustomModel')
    ? JSON.parse(localStorage.getItem('yourCustomModel'))
    : {},

  isRimsIntersecting: false,
  isInteriorIntersecting: false,
}

export const yourModelSlice = createSlice({
  name: 'yourModel',
  initialState,
  reducers: {
    fetchModel: (state, { payload }) => {
      // console.log(payload)
    },
    createYourCustomModel: (state, { payload }) => {
      state.customModel = payload
      localStorage.setItem('yourCustomModel', JSON.stringify(payload))
    },
    rimsIntersecting: (state, { payload }) => {
      state.isRimsIntersecting = payload
    },
    interiorIntesecting: (state, { payload }) => {
      state.isInteriorIntersecting = payload
    },
  },
})
export const {
  createYourCustomModel,
  rimsIntersecting,
  interiorIntesecting,
  fetchModel,
} = yourModelSlice.actions
export default yourModelSlice.reducer
