import React, { useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import { BsGlobe } from 'react-icons/bs'

const links = [
  // { name: 'Audi A8', to: '#', id: 3 },
  // { name: 'Audi Q5', to: '#', id: 4 },
  // { name: 'Audi e-tron', to: '#', id: 5 },
  // { name: 'Account', to: '#', id: 6 },
  // { name: 'Test Drive', to: '#', id: 13 },
  // { name: 'Shop', to: '#', id: 7 },
  // { name: 'Call Center', to: '#', id: 11 },
  // { name: 'Find Us', to: '#', id: 9 },
  // { name: 'Support', to: '#', id: 10 },
  { name: 'Existing Inventory', to: '#', id: 4 },
  { name: 'Certified Pre-Owned', to: '#', id: 5 },
  // { name: 'Trade-In', to: '#', id: 6 },
  // { name: 'Live-In', to: '#', id: 7 },
  // { name: 'Furniture', to: '#', id: 8 },
  // { name: 'Home Automation', to: '#', id: 9 },
  { name: 'Renovations', to: '#', id: 10 },
  { name: 'Accessories', to: '#', id: 11 },
  { name: 'Commercial Real Estate', to: '#', id: 12 },
  { name: 'Locate Us', to: '#', id: 13 },
  { name: 'Support', to: '#', id: 14 },
  { name: 'Business Relations', to: '#', id: 15 },
  { name: 'Investor Relations', to: '#', id: 16 },
]

const MobileMenu = ({ setIsOpen, open }) => {
  useEffect(() => {
    if (open) {
      const width = document.body.clientWidth
      document.body.style.overflowY = 'hidden'
      document.body.style.width = `${width}px`
    } else {
      document.body.style.overflowY = 'visible'
      document.body.style.width = `auto`
    }

    return () => {
      document.body.style.overflowY = 'visible'
      document.body.style.width = `auto`
    }
  }, [open])

  return (
    <AnimatePresence>
      {open && (
        <>
          <motion.div
            className='blur-container'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.2 }}
            transition={{ duration: 0.4, delay: 0.1 }}
            onClick={() => setIsOpen(!open)}
          />
          <motion.div
            initial={{ opacity: 0, width: 150 }}
            animate={{ opacity: 1, width: 300 }}
            exit={{ opacity: 0, width: 150 }}
            transition={{ duration: 0.4, delay: 0.1 }}
            className='mobile-menu'
          >
            <motion.div className='button'>
              <button onClick={() => setIsOpen(!open)}>X</button>
            </motion.div>
            <motion.ul>
              <Link to='/4%20bedroom%20std'>
                <motion.li>4 Bedroom STD</motion.li>
              </Link>

              <Link to='/3%20bedroom%20std'>
                <motion.li>3 Bedroom STD</motion.li>
              </Link>

              <Link to='/2%20bedroom'>
                <motion.li>2 Bedroom</motion.li>
              </Link>
              {links.map((link) => (
                <motion.li key={link.id} id={link.id}>
                  {link.name}
                </motion.li>
              ))}

              <div
                className='c-lang'
                style={{ display: 'flex', justifyContent: 'start' }}
              >
                {/* <BsGlobe />
                <h1>US</h1> */}
              </div>
            </motion.ul>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

export default MobileMenu
