import { Routes, Route } from 'react-router-dom'

// Pages
import Homepage from './pages/Homepage'
import Threebedstd from './pages/threebedstd'
import Threebedext from './pages/threebedext'
import Fourbedstd from './pages/fourbedstd'
import TwoBedroom from './pages/2-Bedroom'
import Fourbedext from './pages/fourbedext'
import Fourbedprem from './pages/fourbedprem'
import CreateModelPage from './pages/CreateModelPage'
import Paymentpage from './pages/Paymentpage'
import NotFound from './pages/NotFound'
import ContactUs from './pages/Contactus'

function App() {
  return (
    <Routes>
      <Route path='/' exact element={<Homepage />} />
      <Route path='3%20bedroom%20std' exact element={<Threebedstd />} />
      <Route path='3-bedroom%20ext.' exact element={<Threebedext/>} />
      <Route path='4%20bedroom%20std' exact element={<Fourbedstd/>} />
      <Route path='4-bedroom%20ext.' exact element={<Fourbedext/>} />
      <Route path='fourbedprem' exact element={<Fourbedprem/>} />
      <Route path='2%20bedroom' exact element={<TwoBedroom />} />
      <Route path='/product/:model' exact element={<CreateModelPage />} />
      <Route path='/product/payment' exact element={<Paymentpage />} />
      <Route path='/contact%20us' exact element={<ContactUs />} />

      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default App
