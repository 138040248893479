import React, { useRef, useEffect, useState } from 'react'
import ModelLayout from '../layouts/ModelLayout'
// import { motion } from 'framer-motion'

// import Description1 from './../components/Model/Description1'
import Description3 from './../components/Model/Description3'
// import Description2 from './../components/Model/Description2'

// import q8dimn from '../images/bgs/q8/q8dimn.jpeg'
// import q8front from '../images/bgs/q8/q8front.webp'
import q8side from '../images/bgs/threebedstandard/3BED_que.png'
import { Link } from 'react-router-dom'

const a3Spec = {
  topSpeed: '1389.8',
  fuel: 'Ground Floor',
  oneToHund: '1st Floor',
  onefeature: 'Open Balcony',
  twofeature: 'Car Lot',
  threefeature: 'Entrance Foyer',
  fourthfeature: 'An Outhouse',
  fifthfeature: '5 Bathrooms',
  sixfeature: 'Elevator',
}

// const s3Spec = {
//   desc1: '451',
//   desc2: 'Petrol',
//   desc3: '2.9',
//   span1: 'km/h',
//   span2: '',
//   span3: 's',
//   h31: 'Top Speed',
//   h32: 'Fuel',
//   h33: '0-100km/h',
//   h61: 'Quickest acceleration—from zero to 60 mph* in as little as 3.1 seconds ',
//   h62: ' Improved handling and aerodynamics allow for a top speed of 162 mph ',
//   h63: ' Dual Motor All-Wheel Drive instantly controls traction and torque, in all weather conditions',
// }

// const rear = {
//   desc1: '380',
//   desc2: 'Sensor',
//   desc3: '670',
//   span1: 'l',
//   span2: '',
//   span3: 'cm',
//   h31: 'Boot capacity',
//   h32: 'Open/Close',
//   h33: 'Height',
//   h61: 'Independent motors digitally control torque to the front and rear bedfinishing ',
//   h62: ' Dual motors respond to changing conditions in as little as 10 milliseconds ',
//   h63: 'Unparalleled traction and control, in all weather conditions ',
// }

// const interior = {
//   desc1: '15',
//   desc2: 'WiFi',
//   desc3: 'All-glass',
//   span1: 'inch',
//   span2: '',
//   span3: '',
//   h31: 'Touch Display',
//   h32: 'Software',
//   h33: 'Roof',
//   h61: 'A touchscreen display designed to improve over time ',
//   h62: 'Over-the-air software updates introduce new features, functionality and performance ',
//   h63: 'An expansive Glass Roof provides more headroom and UV protection ',
// }

// const showAnimation = {
//   initial: {
//     opacity: 0,
//     y: 20,
//   },
//   animate: {
//     opacity: 1,
//     y: 0,
//     transition: {
//       duration: 1,
//       delay: 0.7,
//     },
//   },
//   exit: {
//     opactiy: 0,
//   },
// }

// const showAnimation2 = {
//   initial: {
//     opacity: 0,
//     scale: 0.9,
//   },
//   animate: {
//     opacity: 1,
//     scale: 1,
//     transition: {
//       duration: 1,
//       delay: 0.5,
//     },
//   },
//   exit: {
//     opactiy: 0,
//   },
// }

const Threebedstd = () => {
  const ref = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  //const ref4 = useRef()
  const ref5 = useRef()

  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)
  const [active3, setActive3] = useState(false)
  const [active4, setActive4] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setActive1(true)
      } else {
        setActive1(false)
      }
    })
    observer.observe(ref.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setActive2(true)
      } else {
        setActive2(false)
      }
    })
    observer.observe(ref2.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setActive3(true)
      } else {
        setActive3(false)
      }
    })
    observer.observe(ref3.current)
  }, [])

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const entry = entries[0]
  //     if (entry.isIntersecting) {
  //       setActive4(true)
  //     } else {
  //       setActive4(false)
  //     }
  //   })
  //   observer.observe(ref4.current)
  // }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setActive4(true)
      } else {
        setActive4(false)
      }
    })
    observer.observe(ref5.current)
  }, [])

  return (
    <ModelLayout>
      <div className='model-page'>
        <div className='model-banner model-q5'>
          <Description3
            name={'Three Bedroom Std'}
            specification={a3Spec}
            btn={'Order Now'}
            link={'/product/threebedstd'}
          />
        </div>

        <div className='black-container-parent'>
        <div className='whiteheader'>
            <h2>Interior of the Facility</h2>
        </div>
        <div className='model-banner interiorthree'></div>
        </div>

        {/* <div className='model-intro-text mit1'>
          <div>
            <h5>Safety</h5>
            <h2>Built for safety</h2>

            <div>
              <p>
                Safety is the most important part of every Tesla. We design our
                vehicles to exceed safety standards.
              </p>
            </div>

            <div>
              <h3>5-star Rating</h3>
              <p>
                Model 3 achieved NHTSA 5-star safety ratings in every category
                and subcategory.
              </p>
            </div>

            <div>
              <h3>Top Safety Pick+</h3>
              <p>
                Model 3 received the IIHS Top Safety Pick+ award, with top
                ratings in all crashworthiness and front crash prevention
                categories.
              </p>
            </div>
          </div>

          <div>
            <img src={q8dimn} alt={q8dimn} />

            <ul>
              <li>
                <h3>
                  <span>1</span>Rigid Structure
                </h3>
                <p>
                  A material mix of aluminum and steel help to achieve the best
                  structural rigidity, and increased occupant safety.
                </p>
              </li>
              <li>
                <h3>
                  <span>2</span>Impact Protection
                </h3>
                <p>
                  Combined with impact-absorbing rails and center pillar
                  reinforcements, the strength and support of the rigid battery
                  pack provides protection from every side.
                </p>
              </li>
              <li>
                <h3>
                  <span>3</span>Very Low Rollover Risk
                </h3>
                <p>
                  The position and weight of the floor-mounted battery pack
                  provides a very low center of gravity—allowing for a very low
                  rollover risk.
                </p>
              </li>
            </ul>

            <Link to='/design/q8'>
              {' '}
              <button>Order Now</button>
            </Link>
          </div>
        </div> */}

        <div ref={ref} className='model-banner model-sq5'>
          {/* {active1 && (
            <Description2
              showAnimation={showAnimation}
              name={'Audi SQ8'}
              specification={s3Spec}
            />
          )} */}
        </div>

        <div className='model-intro-text mit2'>
          <div>
            <h5>(10’ 0” * 13’ 2”)</h5>
            <h2>Kitchen Area</h2>
          </div>

          <div>
            <p>
            The ambience of the home that offers satisfaction in a hygienic censored capacious kitchen unit with marble countertops, 
            and 450*450mm Polished Beige Gray Porcelain Floor Tiles, plugged into an ultra-modern, structurally, engineered frame for your comfort. 
            A kitchen Yard of 13’ 2’’ * 4’ 0” is accommodated.
            </p>
          </div>
        </div>

        <div ref={ref2} className='model-banner rear-q5'>
          {/* {active2 && (
            <Description2
              showAnimation={showAnimation}
              name={''}
              specification={rear}
            />
          )} */}
        </div>

        <div className='model-intro-text mit2'>
          <div>
            <h5>(25’ 6” * 13’ 2”)</h5>
            <h2>Living Area with Dinning</h2>
          </div>

          <div>
            <p>
            A spacious and effervescent lounge elegantly capped with a Plasterboard celling and a 450*450mm Polished Beige Gray Porcelain Floor Tiles, 
            that offers convenience and comfort, and provides, reception and hospitality for the home and your entertained guests for respect and cultural 
            acceptance. A couch potato or the African buzzing bee, whoever, a parlor, situated to capture the mountainous vegetative cover scenery, 
            invites your appreciation of nature. A dinning Space for your meals time relaxation is accommodated.
            </p>
          </div>
        </div>

        {/* <div className='model-intro-text mit2'>
          <div>
            <h5>Autopilot</h5>
            <h2>Future of Driving</h2>
          </div>

          <div>
            <p style={{ marginBottom: '6rem' }}>
              Autopilot enables your car to steer, accelerate and brake
              automatically within its lane. Full Self-Driving Capability
              introduces additional features and improves existing functionality
              to make your car more capable over time including:
            </p>

            <ul>
              <li>
                <h3>Features</h3>
                <p>
                  A material mix of aluminum and steel help to achieve the best
                  structural rigidity, and increased occupant safety.
                </p>
              </li>
              <li>
                <h3>Full Self-Driving Hardware</h3>
                <p>
                  Every new Model 3 comes standard with advanced hardware
                  capable of providing Autopilot features today, and full
                  self-driving capabilities in the future—through software
                  updates designed to improve functionality over time.
                </p>
              </li>
              <li>
                <h3>The Future of Autopilot</h3>
                <p>
                  All Tesla vehicles have the hardware needed in the future for
                  full self-driving in almost all circumstances, at a safety
                  level we believe will be at least twice as good as the average
                  human driver.
                </p>
              </li>
            </ul>
          </div>
        </div> */}

        <div ref={ref3} className='model-banner interior-q5'>
          {/* {active3 && (
            <Description2
              showAnimation={showAnimation}
              name={''}
              specification={interior}
            />
          )} */}
        </div>

        <div className='model-intro-text mit2'>
          <div>
            <h5>(7’ 0” * 3’ 6”)</h5>
            <h2>Visitor’s Washroom:</h2>
          </div>

          <div>
            <p>
            An additional single unit washroom for visitors, decorated with 300*300 Polished Light Gray Porcelain Floor Tiles, non-slip.
            </p>
          </div>
        </div>

        <div ref={ref5} className='model-banner interior-stairs'>
          {/* {active3 && (
            <Description2
              showAnimation={showAnimation}
              name={''}
              specification={interior}
            />
          )} */}
        </div>

        <div className='model-intro-text mit2'>
          <div>
            {/* <h5>(7’ 0” * 3’ 6”)</h5> */}
            <h2>A Stair Well to 1st Floor</h2>
          </div>

          <div>
            <p>
            A spiral, sweeping staircase that leads up to the 1st floor, barricaded with a stainless steel and / or, finely polished wooden furniture, 
            fixed on the wall or onto vertical poles at the side for your climbing support; includes: landings, newel posts, handrails, balustrades and 
            additional parts.
            </p>
          </div>
        </div>

        <div ref={ref5} className='model-banner interior-outhouse'>
          {/* {active3 && (
            <Description2
              showAnimation={showAnimation}
              name={''}
              specification={interior}
            />
          )} */}
        </div>

        <div className='model-intro-text mit2'>
          <div>
            <h5>(8’ 0” * 7’ 0”)</h5>
            <h2>An Outhouse</h2>
          </div>

          <div>
            <p>
            A Boys Quarters ensuite, that offers the convenience of residence for your house assistant, is an addendum on the Ground floor, extended behind the 
            main structure that comes with a washroom capacity of 7’ 0” * 3’ 6” decorated with 300*300 Polished Light Gray Porcelain Floor Tiles, non-slip.  
            </p>
          </div>
        </div>

        <div className='model-intro-text mit2'>
          <div>
            <h2>Elevators</h2>
          </div>

          <div>
            <p style={{ marginBottom: '6rem' }}>
            Installed with ultra-modern elevators with state-of-the-art technology that does not require any battery backup or temporary power supply in the event of 
            power failure. All safety features including: Telephone, Light, Fan, Child Switch, Emergency Descend and Alarm are in standard shape. It comes with 
            self-supported structure, that requires no civil works.
            </p>

            {/* <ul>
              <li>
                <h3>Features</h3>
                <p>
                  A material mix of aluminum and steel help to achieve the best
                  structural rigidity, and increased occupant safety.
                </p>
              </li>
              <li>
                <h3>Full Self-Driving Hardware</h3>
                <p>
                  Every new Model 3 comes standard with advanced hardware
                  capable of providing Autopilot features today, and full
                  self-driving capabilities in the future—through software
                  updates designed to improve functionality over time.
                </p>
              </li>
              <li>
                <h3>The Future of Autopilot</h3>
                <p>
                  All Tesla vehicles have the hardware needed in the future for
                  full self-driving in almost all circumstances, at a safety
                  level we believe will be at least twice as good as the average
                  human driver.
                </p>
              </li>
            </ul> */}
          </div>
        </div>


        <div>
        <div className='blackheader'>
          <h3>First Floor</h3>
        </div>

        <div className='white-container-parent'>
          <div className='white-container'>
            <div className='one'>
              {' '}
              <div className='model-banner interior3-q8' />
              <div className='model-intro-text'>
                <h2>Bedroom 1-Ensuite (14’ 6” * 13’ 2”)</h2>
                <div>
                  <p>
                  Breathtaking, timeless clean and fairly spacious Master Suite with 450*450mm Polished Beige Gray Porcelain Floor Tiles, designed with a large 
                  shower and loo (9’ 2” * 5’ 0”) in the ensuite, decorated with 300*300 Polished Light Gray Porcelain Floor Tiles, non-slip. Provides views over 
                  landscaped gardens and mountainous scenery of nature through sliding and projected windows. Elegantly, embedded with wardrobe fittings
                  </p>
                </div>
              </div>
            </div>
            <div className='two'>
              {' '}
              <div className='model-banner interior4-q8' />
              <div className='model-intro-text'>
                <h2>Bedroom 2-Ensuite (9’ 8” * 13’ 0”)</h2>
                <div>
                  <p>
                  Luxury, sophisticated, clean and fairly spacious Master Suite with 450*450mm Polished Beige Gray Porcelain Floor Tiles, designed with a large shower 
                  and loo (9’ 2” * 5’ 0”) in the ensuite, decorated with 300*300 Polished Light Gray Porcelain Floor Tiles, non-slip. Provides views over landscaped 
                  gardens and mountainous scenery of nature through sliding and projected windows. Elegantly, embedded with wardrobe fittings. Provides views over 
                  landscaped gardens and mountainous scenery of nature. Elegantly, embedded with wardrobe fittings.
                  </p>
                </div>
              </div>
            </div>
            <div className='three'>
              <div className='model-banner interior5-q8' />
              <div className='model-intro-text'>
                <h2>Bedroom 3-Ensuite (10’ 0” * 13’ 0”)</h2>
                <div>
                  <p>
                  Luxury, lovely clean and fairly spacious Master suite with 450*450mm Polished Beige Gray Porcelain Floor Tiles, designed with a large shower and loo 
                  (5’ 8” * 8’ 6”) in the ensuite, decorated with 300*300 Polished Light Gray Porcelain Floor Tiles, non-slip. Provides views over landscaped gardens 
                  and mountainous scenery of nature through sliding and projected windows.  Elegantly, embedded with wardrobe fittings. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div className='blackheader'>
          <h3>Exterior of the Facility</h3>
        </div>
        <div className='model-banner interior2-q8' />
        <div className='black-container-parent'>
          <div className='model-intro-text black mit3'>
            <div>
              <h5>e-secured Community</h5>
              <h2>Iron-gated</h2>
            </div>
            <div>
              <p>
              Available with electronic gates, swing gates, that hinge on gate posts at the sides of driveway entrances and sliding gates, moved by static 
              motors with backed up batteries, remote controls and other settings that can be solar-powered.
              </p>
            </div>
          </div>
        </div>
        <div className='black-container-parent'>
          <div className='black-container'>
            <div className='one'>
              {' '}
              <div className='model-banner interior3-q8' />
              <div className='model-intro-text black'>
                <h2>A 2-Car Lot (24’ 8” * 17’ 0”)</h2>
                <div>
                  <p>
                  A consummate Car Lot, designed with a charming pre-cast Floor Slabs of C25N/mm2 interlaced with a quintessential carpet grass to contain 
                  two private vehicles at the iconic foyer.
                  </p>
                </div>
              </div>
            </div>
            <div className='two'>
              {' '}
              <div className='model-banner interior4-q8' />
              <div className='model-intro-text black'>
                <h2>Compound</h2>
                <div>
                  <p>
                  Idyllic inner compound for the units with charming pre-cast Floor Slabs of C25N/mm2 interlaced with a quintessential carpet grass, and 
                  floral injections at the façade
                  </p>
                </div>
              </div>
            </div>
            <div className='three'>
              <div className='model-banner interior5-q8' />
              <div className='model-intro-text black'>
                <h2>Entrance Foyer (5’ * 9’ 8”)</h2>
                <div>
                  <p>
                  Illuminating Entrance Foyer projected with a lush pre-cast Floor Slabs of C25N/mm2 interlaced with a quintessential carpet grass for your comfort, 
                  that offers a brief welcome lobby to usher your guests into the living room area.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='black-container'>
            <div className='one'>
              {' '}
              <div className='model-banner interior6a-q8' />
              <div className='model-intro-text black'>
                <h2>Open Balcony (19’ 6” * 11’ 0”)</h2>
                <div>
                  <p>
                  An open Balcony with 300*300 Polished Light Gray Porcelain Floor Tiles, non-slip is designed for the 1st Floor to enhance the life quality of the 
                  residents and serves as an outdoor room, granting access to fresh air and ever-changing site seeing of the serene natural atmosphere from the 
                  angle elevated view. It also, enlarges the living space and could contain some furniture and plants for the aesthetic improvement of the home, 
                  and comes with Extended Cantilevers for relaxation.
                  </p>
                </div>
              </div>
            </div>
            <div className='two'>
              {' '}
              <div className='model-banner interior7a-q8' />
              <div className='model-intro-text black'>
                <h2>Solar Panels</h2>
                <div>
                  <p>
                  Solar photovoltaic panels installed on the hidden roof, ground or on a solar canopy. Comes with a Tesla Powerwall that allows for the storage of 
                  excess energy which is produced  by the solar panels, and allows for powering the home during a grid outage.
                  </p>
                </div>
              </div>
            </div>
            <div className='three'>
              <div className='model-banner interior8a-q8' />
              <div className='model-intro-text black'>
                <h2>Standby Generator</h2>
                <div>
                  <p>
                  A standby commercial Generator with an Automatic Transfer Switch (ATS), installed, that ensures, that in the event of a power outage, the standby 
                  generator will automatically, kick in and keep power running for the property, and once power is restored to the main power grid, the generator 
                  automatically shuts off – a mechanism that guarantees hassle free functioning.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='model-intro-text black'>
          
          <h1>Cul-de-sac</h1>

          <div className='specs-parent'>
            <Link to='/product/threebedstd'>
              {' '}
              <button>Order Now</button>
            </Link>
            <img src={q8side} alt='' />
          </div>
        </div>
      </div>
    </ModelLayout>
  )
}

export default Threebedstd
