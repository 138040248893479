import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { createYourCustomModel } from '../../../redux/yourModelSlice'

const NewFeatures = ({ createCustomModel }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const model = params.model

  return (
    <>
      {/* <div className='model-self-driving'>
        <h2 className='self-d-heading'>Additional Options</h2>
        <ul>
          <li>Air Solutions</li>
          <p>
          <a href="https://www.samsung.com/africa_en/air-conditioners/all-air-conditioners/">- Air Conditioning </a>
          </p>
          <li>Refrigerators</li>
          <p>
          <a href="#">- Fridge / Freezer </a>
          </p>
          <li>Laundry</li>
          <p>
          <a href="https://www.samsung.com/africa_en/washers-and-dryers/all-washers-and-dryers/?front-loader">- Washing Machine </a>
          <a href="https://www.samsung.com/africa_en/washers-and-dryers/dryers/">- Dryer </a>
          </p>

          <li>Cooking Appliances</li>
          <p>
          <a href="#">- Cooker/ Hob/ Extractor </a>
          </p>
          
          <li>Dish Washer</li>
          <li>Water Heater</li>
        </ul>
      </div> */}

      {/* <div className='model-comming-soon'>
        <h5>Additional Options</h5>
        <ul>
          <li>Autosteer od city streets</li>
        </ul>
        <p>
          The currently enabled features require active driver supervision and
          do not make the vehicle autonomous. The activation and use of these
          features are dependent on achieving reliability far in excess of human
          drivers as demonstrated by billions of miles of experience, as well as
          regulatory approval, which may take longer in some jurisdictions. As
          these self-driving features evolve, your car will be continuously
          upgraded through over-the-air software updates.
        </p>
      </div> */}

      {/* <div className='model-order'>
        <h1>Order Now</h1>

        <Link to='/product/payment'>
          <button
            onClick={() => dispatch(createYourCustomModel(createCustomModel))}
          >
            Continue to payment
          </button>
        </Link>
      </div> */}
    </>
  )
}

export default NewFeatures
