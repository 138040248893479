import React from 'react'
import HomepageLayout from '../layouts/HomeLayout'
import { Link } from 'react-router-dom'

const ContactUs= () => {
  return (
    <HomepageLayout>
      <div className='not-found'>
        <h1><b>Sucasa Properties</b></h1>
        <h1>023 456 7890</h1>

        {/* <Link to='/'>
          <button>Home</button>
        </Link> */}
      </div>
    </HomepageLayout>
  )
}

export default ContactUs
